import { PAGES_IDENTIFIER } from "@/constants";
const { useJudgeTime } = useLibs();
const { isPlusNeedPopup } = useNeedLoginPopup();

/**
 * playButtonConfig
 * from the selectedItem.type, determine the look and action
 * of the play button.
 *
 * returns an object
 *
 * {
 *  label,
 *  icon,
 *  onClick: {
 *    func: function signature,
 *    args: array,
 *  }
 * }
 */

export default function (media) {
  const localePath = useLocalePath();
  const route = useRoute();
  const getPageTypeFromNuxtMeta = route?.meta?.pageType ?? null;
  let contentType = media.type;

  if ([PAGES_IDENTIFIER.SEARCH].includes(getPageTypeFromNuxtMeta)) {
    contentType = "Detail";
  }

  if (media?.meta?.type === "kids_songs") {
    contentType = "Song";
  }

  const handlePlusClick = (callback) => {
    return (args) => {
      if (isPlusNeedPopup(media)) return;
      callback(args);
    };
  };
  /**
   * on LivePrograms,
   *
   * Label logic
   * If program is...
   * in the future, label will be ”Available on Oct 18, 7pm”
   * is later today, label will be "Available at 7pm"
   * is currently running, label will be "Play"
   *
   * Click event logic
   * If program is...
   * in the future, disabled
   * is later today, disabled
   * is currently running, enabled and should navigate to the live player page
   * with the channel id
   */
  const onLiveProgram = (config) => {
    const { inValid: disabled, content } = useJudgeTime(media);
    if (disabled) {
      config.label = content;
      config.icon = null;
    } else {
      const externalChannelId =
        media?.channel?.externalChannelId || media?.externalChannelId;
      config.onClick = {
        func: handlePlusClick(navigateTo),
        args: [
          {
            path: localePath(`/live/${externalChannelId}`),
          },
        ],
      };
    }

    config.disabled = disabled;
  };

  /**
   * onShow
   *
   * Series and Season should open the details page
   */
  const onShow = (config) => {
    config.label = "Details";
    config.icon = null;
    config.onClick = {
      func: () => useInfoClickEvent(media),
      args: [],
    };
  };

  /**
   * External links should have a label of "Open"
   * and the click event should open the external link
   * on a new tab
   */
  const onBrowserLink = (config) => {
    const link = media.extrafields.find((e) => e.name == "Link");

    if (!link) {
      config.label = "Link unavailable";
      config.disabled = true;
      return;
    }

    config.label = "Open";
    config.icon = null;
    config.onClick = {
      func: navigateTo,
      args: [
        link.value,
        {
          open: {
            target: "_blank",
          },
        },
      ],
    };
  };

  /**
   * onThematic
   * Thematic entries should navigate to the theme page
   * with the right parameters
   */
  const onThematic = (config) => {
    const link = media.extrafields.find(
      (e) => e.name == "external_category_id"
    );
    if (!link) {
      config.label = "Link unavailable";
      config.disabled = true;
      return;
    }

    config.label = "Open";
    config.icon = null;
    config.onClick = {
      func: handlePlusClick(navigateTo),
      args: [localePath(`/theme/${link.value}`)],
    };
  };

  const onGenre = (config) => {
    config.label = "Open";
    config.icon = null;
    config.onClick = {
      func: () =>
        handlePlusClick(
          navigateTo({
            path: localePath(`/genres/programs/${encodeURI(media.name)}`),
            query: media.api.query,
          })
        ),
      args: [],
    };
  };

  const onPlayerLink = (config) => {
    config.label = "Play";
    config.icon = "IconPlay";
    config.onClick = {
      func: handlePlusClick(() => {
        const playerLinkCookie = useCookie("player_link");
        playerLinkCookie.value = encodeURI(media.api.path);
        navigateTo({ path: localePath("/watch/external/") });
      }),
      args: [],
    };
  };

  /**
   * on Detail
   *
   * Show detail modal
   */
  const onDetail = (config) => {
    console.info("onDetail!", media);
    config.label = "Details";
    config.icon = media.type === "Movie" ? "IconPlay" : null;
    config.onClick = {
      func: () => useInfoClickEvent(media),
      args: [],
    };
  };

  /**
   * on Songs
   *
   */
  const onSong = (config) => {
    config.label = null;
    config.icon = "IconPlay";
    config.onClick = {
      func: () => {
        const isKidsMode = useIsKidsMode();

        navigateTo({
          path: localePath(
            isKidsMode.value
              ? "/kids/search/songs-player"
              : "/search/songs-player"
          ),
          query: { ...route.query, id: media.id },
        });
        return;
      },
      args: [],
    };
  };

  let obj = {
    disabled: false,
    label: "Play",
    icon: "IconPlay",
    onClick: {
      func: handlePlusClick(usePlayClickEvent),
      args: [media],
    },
  };

  const mapping = {
    LiveProgram: onLiveProgram,
    Season: onShow,
    Series: onShow,
    Browser: onBrowserLink,
    Theme: onThematic,
    Genre: onGenre,
    Player: onPlayerLink,
    Detail: onDetail,
    Song: onSong,
  };

  if (Object.keys(mapping).includes(contentType)) {
    mapping[contentType](obj);
  }

  return {
    ...obj,
    label: obj.label,
  };
}
