import { useSessionStore } from "@/stores/session";
export default function (media, useQueryInstead = false) {
  const sessionStore = useSessionStore();

  const nuxtApp = useNuxtApp();

  /**
   * for movie, externalId will be at the root of the object,
   * for series, inside the tvShowSeries object
   */

  let query = {
    video_external_id: media.externalId,
    assetExternalId: media.assetExternalId || media.externalContentId,
    externalContentId: media.externalId,
    externalSeriesId: media.tvShowSeries?.externalId,
    type: media.type,
  };

  if (useQueryInstead) {
    Object.assign(query, nuxtApp._route.query);
  }

  let path = "/watch";
  if (sessionStore.activeProfile?.isKid) {
    path = "/kids/watch";
  }

  // whichTypeNeedDispatch("home", media);
  return navigateTo({
    path: useLocalePath()(path),
    query,
  });
}
