<template>
  <div
    :class="inFloater ? 'gap-x-2' : 'gap-x-2 sm:gap-x-4'"
    class="rounded-lg flex items-center transition duration-200"
  >
    <slot name="primary">
      <ButtonsCta
        :disabled="playButtonConfig?.disabled"
        class="variant-primary py-2"
        @click.stop="handleOtherClick"
      >
        <div class="w-full flex items-center gap-x-[6px]">
          <Icon
            v-if="playButtonConfig.icon"
            :size="iconSize"
            name="IconPlay"
          ></Icon>
          <span
            v-if="showLabel"
            class="font-Abu md:text-button-medium text-button-big text-white"
          >
            {{ $t(playButtonConfig.label) }}
          </span>
        </div>
      </ButtonsCta>
    </slot>

    <MyListAddToMyWishlistBtn
      v-if="['Movie', 'Series', 'Season'].includes(mediaType)"
      :outlined="true"
      :media="media"
    />

    <template v-if="showInfoButton">
      <ButtonsIcon
        v-if="mediaType == 'Movie'"
        class="variant-secondary outlined"
        :breakpoints="{ lg: 24, md: 20, sm: 20 }"
        icon-name="IconInfo"
        @click="clickInfoHandle(media)"
      >
      </ButtonsIcon>
    </template>
    <UiShareButton
      v-if="
        ['Movie', 'Season', 'Series', 'LiveProgram'].includes(mediaType) &&
        !inFloater
      "
      :title="media?.name"
      :url="media?.shareLink"
    />

    <slot name="extraIcons"></slot>
  </div>
</template>

<script setup>
import { useSessionStore } from "@/stores/session";
import { useGTMStore } from "@/stores/gtm";

const sessionStore = useSessionStore();
const { isPlusNeedPopup } = useNeedLoginPopup();

const route = useRoute();
const gtmStore = useGTMStore();

const props = defineProps({
  media: Object,
  inDetail: {
    type: Boolean,
    default: () => false,
  },

  inFloater: {
    type: Boolean,
    default: () => false,
  },
  /**
   * dont show labels on floaters.
   */
  showLabel: {
    type: Boolean,
    default: () => true,
  },
  gtmTitleLabel: String,
});

const { isLgAndUp } = useBreakpoints();

const iconSize = computed(() => {
  if (isLgAndUp.value) {
    return 24;
  } else {
    return 20;
  }
});

const mediaType = computed(() => {
  return route.query.type || props.media.type;
});

const playButtonConfig = computed(() => {
  if (!props.media) return {};
  return useCtaItems(props.media);
});

/**
 * show info button only on the hero banner page
 * but this component is used in:
 *
 * hero banner
 * detail page banner
 * floater info
 */
const showInfoButton = computed(() => {
  return !props.inDetail || props.inFloater;
});

const clickInfoHandle = (media) => {
  useInfoClickEvent(media);
  gtmStore.setListName(props.gtmTitleLabel);
};
const handleOtherClick = () => {
  if (!props.showLabel) {
    if (isPlusNeedPopup(props.media)) {
      return;
    }
  }
  console.log("zheli", props.showLabel, playButtonConfig.label);
  gtmStore.setListName(props.gtmTitleLabel);
  playButtonConfig?.value?.onClick.func(
    ...playButtonConfig?.value?.onClick.args
  );
};
</script>

<style lang="scss" scoped></style>
