<template>
  <div class="overlay__tags flex gap-x-2 justify-start items-center">
    <div v-if="isLive || isPlus" class="flex gap-x-2">
      <UiTagLive v-if="isLive"></UiTagLive>
      <UiTagPlus v-if="isPlus"></UiTagPlus>
    </div>
    <div class="flex gap-x-1 items-center pt-0.5">
      <span
        v-if="prefix"
        :class="[opacityClass]"
        class="text-body-small lg:text-body-medium text-white"
      >
        {{ prefix }}
      </span>
      <div
        v-if="prefix && genreList?.length > 0"
        :class="[opacityClass]"
        class="px-1 text-white"
      >
        |
      </div>
      <div class="flex flex-wrap items-center gap-x-1 break-words">
        <span
          :key="key"
          v-for="(g, key) in genreList"
          :class="[opacityClass]"
          class="text-body-small lg:text-body-medium text-white"
        >
          {{ g.name }}
          <Icon
            v-if="key < genreList.length - 1"
            name="IconDot"
            class="fill-white"
            size="24"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  media: Object,
  opacityClass: String,
  inDetail: Boolean,
});

const { t } = useI18n();

const route = useRoute();

const type = computed(() => {
  return route.query?.type || props.media?.type;
});

const contentType = computed(() => {
  return route.query?.contentType || props.media?.contentType;
});

const isLive = computed(() => useIsLive(props.media));

const isPlus = computed(() => {
  switch (type.value) {
    case "Season":
    case "Series":
      /**
       * since the detail page displays season instead of the series,
       * the value of isPlus should be from the currently showing season
       */
      if (props.inDetail) {
        // find current season
        const currentSeason = props.media?.tvShowSeasons?.find(
          (s) => s.seriesExternalId == route.query?.externalSeriesId
        );

        if (!currentSeason) return false;

        // return currentSeason?.isPlus || false;
        return currentSeason?.showPlusLabel;
      }

      return props.media?.showPlusLabel;

    case "Movie":
    default:
      return props.media?.showPlusLabel;
  }
});

const genreList = computed(() => {
  let list = [];
  switch (type.value) {
    case "Movie":
      list =
        props.media?.aggregatedVideo?.genreEntityList ||
        props.media?.genreEntityList;
      break;
    case "Season":
      const season =
        props?.media?.tvShowSeason || props?.media?.tvShowSeasons?.[0];
      list = season?.genreEntityList || season?.genres;
      break;
    case "Series":
      list = props.media?.tvShowSeries?.genres;
      if (contentType.value == "Podcast") {
        list = props.media.genreEntityList;
      }
      break;
    default:
      list = props.media?.genreEntityList;
      break;
  }

  const output = list?.filter((l) => {
    const isNotMajidSong = l?.externalId != "Majid Songs";
    const isNotBlank = l.name.trim().length > 0;

    return isNotMajidSong && isNotBlank;
  });

  return output;
});

const prefix = computed(() => {
  switch (type.value) {
    case "Movie":
      return props.media?.aggregatedVideo?.year || props.media?.year;
    case "Season":
      if (props.inDetail) {
        let season;

        if (route.query?.externalSeasonId) {
          season = props.media.tvShowSeasons.find(
            (s) => s.externalId == route.query.externalSeasonId
          );
        } else {
          season =
            props?.media?.tvShowSeason || props?.media?.tvShowSeasons?.[0];
        }

        return season?.name || t("seasonLabel", { s: season?.seasonNumber });
      }
      const s =
        props.media.tvShowSeason?.seasonNumber || props.media.seasonNumber;
      return t("seasonLabel", { s });
    case "Series":
      if (props.inDetail) {
        let season;

        if (route.query?.externalSeasonId) {
          season = props.media.tvShowSeasons.find(
            (s) => s.externalId == route.query.externalSeasonId
          );
        } else {
          season =
            props?.media?.tvShowSeason || props?.media?.tvShowSeasons?.[0];
        }

        return season?.name || t("seasonLabel", { s: season?.seasonNumber });
      }

      return t("nSeasons", {
        n:
          props.media?.tvShowSeries?.numberOfSeasons ||
          props.media?.numberOfSeasons,
      });
    default:
      break;
  }
});
</script>

<style lang="scss" scoped></style>
