const { isPlusNeedPopup } = useNeedLoginPopup();

export default function (media, extraOpts = {}) {
  // if (isPlusNeedPopup(media)) return;
  const route = useRoute();

  useAllPlayers().pauseAll();

  const query = {
    video_external_id: media.externalId,
    assetExternalId: media.assetExternalId,
    externalContentId: media.externalId || media?.externalContentId,
    externalSeriesId: media.seriesExternalId || media?.externalSeriesId,
    externalSeasonId: media.seasonExternalId,
    type: media.type,
  };

  if (media.contentType) {
    query.contentType = media.contentType;
  }

  navigateTo({
    query: {
      media: useKebabCase(media.name),
      ...query,
    },
    ...extraOpts,
  });
}
